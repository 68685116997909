// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "s_fQ d_fQ d_by d_bG d_bH";
export var navbarDividedRight = "s_fR d_fR d_by d_bH";
export var menuDesign6 = "s_st d_fM d_by d_dv d_bN d_bH";
export var menuDesign7 = "s_sv d_fM d_by d_dv d_bN d_bH";
export var menuRight = "s_sw d_fM d_by d_dv d_bN d_bH";
export var menuLeft = "s_sx d_fM d_by d_dv d_bN d_bH";
export var menuCenter = "s_sy d_fN d_fM d_by d_dv d_bN d_v d_bC d_bH";
export var menuDivided = "s_qC d_fN d_fM d_by d_dv d_bN d_v d_bC";
export var menuDesign5 = "s_qN d_fP d_fM d_by d_dv d_bN d_bH";
export var isBurger = "s_sz";
export var navbarItem = "s_qD d_bw";
export var navbarLogoItemWrapper = "s_fY d_fY d_bB d_bN";
export var burgerToggleVisibleOpen = "s_sB d_gd d_bx d_Y d_br";
export var burgerToggleVisible = "s_sC d_gd d_bx d_Y d_br";
export var burgerToggle = "s_sD d_gd d_bx d_Y d_br d_Y";
export var burgerToggleOpen = "s_sF d_gd d_bx d_Y d_br";
export var burgerInput = "s_sG d_f2 d_v d_G d_bx d_cb d_dk d_bc d_Z d_7 d_6 d_3 d_8";
export var burgerIcon = "s_sH d_f3 d_v d_G";
export var burgerLine = "s_sJ d_f1";
export var burgerMenuDesign6 = "s_sK d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign7 = "s_sL d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuLeft = "s_sM d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDesign5 = "s_sN d_f8 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuRight = "s_sP d_f8 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuCenter = "s_sQ d_f9 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var burgerMenuDivided = "s_sR d_f7 d_f6 d_f4 d_f5 d_Z d_cb d_dl d_br d_dv";
export var secondary = "s_sS d_bB d_bN";
export var staticBurger = "s_sT";
export var menu = "s_sV";
export var navbarDividedLogo = "s_sW";
export var nav = "s_sX";