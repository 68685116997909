// extracted by mini-css-extract-plugin
export var warningBackgroundlight = "g_mP";
export var warningBackgrounddark = "g_mQ";
export var warningBackgroundcustom = "g_mR";
export var bottom = "g_gH";
export var modal = "g_mS";
export var container = "g_mT";
export var btnWrapper = "g_d1 d_bC d_bN d_bH";
export var elementWrapper = "g_mV d_bC d_bN d_dv";
export var titleWrapper = "g_mW d_bC d_bN";
export var cookieButtons = "g_mX d_by d_bC d_bN d_bH";
export var btn = "g_mY d_by d_bC d_bN";
export var link = "g_mZ";
export var decline = "g_m0 g_mY d_by d_bC d_bN";
export var row = "g_m1";