// extracted by mini-css-extract-plugin
export var dark = "t_sY";
export var darkcookie = "t_sZ";
export var tintedcookie = "t_s0";
export var regularcookie = "t_s1";
export var darkbase = "t_s2";
export var tintedbase = "t_s3";
export var regularbase = "t_s4";
export var darkraw = "t_s5";
export var tintedraw = "t_s6";
export var regularraw = "t_s7";
export var darkchick = "t_s8";
export var tintedchick = "t_s9";
export var regularchick = "t_tb";
export var darkherbarium = "t_tc";
export var tintedherbarium = "t_td";
export var regularherbarium = "t_tf";
export var darkholiday = "t_tg";
export var tintedholiday = "t_th";
export var regularholiday = "t_tj";
export var darkoffline = "t_tk";
export var tintedoffline = "t_tl";
export var regularoffline = "t_tm";
export var darkorchid = "t_tn";
export var tintedorchid = "t_tp";
export var regularorchid = "t_tq";
export var darkpro = "t_tr";
export var tintedpro = "t_ts";
export var regularpro = "t_tt";
export var darkrose = "t_tv";
export var tintedrose = "t_tw";
export var regularrose = "t_tx";
export var darktimes = "t_ty";
export var tintedtimes = "t_tz";
export var regulartimes = "t_tB";
export var darkwagon = "t_tC";
export var tintedwagon = "t_tD";
export var regularwagon = "t_tF";