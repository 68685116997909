// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_sl d_gv d_cr";
export var heroHeaderCenter = "r_gw d_gw d_cr d_dv";
export var heroHeaderRight = "r_gx d_gx d_cr d_dw";
export var heroParagraphLeft = "r_sm d_gr d_cv";
export var heroParagraphCenter = "r_gs d_gs d_cv d_dv";
export var heroParagraphRight = "r_gt d_gt d_cv d_dw";
export var heroBtnWrapperLeft = "r_sn d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "r_sp d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "r_sq d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "r_sr d_gq d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "r_ss d_gp d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "r_rW q_rW";
export var heroExceptionNormal = "r_rX q_rX";
export var heroExceptionLarge = "r_rY q_rY";
export var Title1Small = "r_rx q_rx q_q2 q_q3";
export var Title1Normal = "r_ry q_ry q_q2 q_q4";
export var Title1Large = "r_rz q_rz q_q2 q_q5";
export var BodySmall = "r_rM q_rM q_q2 q_rl";
export var BodyNormal = "r_rN q_rN q_q2 q_rm";
export var BodyLarge = "r_rP q_rP q_q2 q_rn";